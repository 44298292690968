import { AppIcon, FontelloIcon } from '../models/icon.model';

import { css } from './css';

export interface FeatureIconConfig {
    icon: AppIcon;
    bgColor?: string;
    label?: string;
    key: string;
}

// Using the type causes name refactoring to not work properly
export const FEATURE_ICONS: { [key: string]: FeatureIconConfig } = {
    alertTypes: { icon: { faIcon: 'fas fa-exclamation-triangle' }, bgColor: css.colors.GRAY, label: 'configuration.page-title.alert-types', key: 'alertTypes' },
    apiKeys: { icon: { faIcon: 'fas fa-laptop-code' }, bgColor: css.colors.GRAY_DK, label: 'configuration.api-keys.api-key', key: 'apiKey' },
    beacons: { icon: { faIcon: 'fas fa-location-crosshairs' }, bgColor: css.colors.DK_RED, label: 'beacon.beacons', key: 'beacons' },
    structures: { icon: { faIcon: 'fas fa-building' }, label: 'structures.structures', key: 'structures' },
    buildings: { icon: { faIcon: 'fas fa-building' }, label: 'buildings.buildings', key: 'buildings' },
    companies: { icon: { faIcon: 'fas fa-industry' }, bgColor: css.brandingColors.W_GREEN, label: 'company.companies', key: 'companies' },
    craft: { icon: { faIcon: 'fa fa-tools' }, bgColor: css.brandingColors.W_LT_GRAY, label: 'craft.craft', key: 'craft' },
    tag: { icon: { faIcon: 'fa fa-tag' }, bgColor: css.brandingColors.W_LT_GRAY, label: 'tag.tag', key: 'tag' },
    dashboard: { icon: { faIcon: 'fas fa-analytics' }, bgColor: css.brandingColors.W_LT_GRAY, label: 'dashboard.dashboard', key: 'dashboard' },
    dvr: { icon: { faIcon: 'fas fa-history' }, label: 'shared.map.controls.dvr', key: 'dvr' },
    earpiece: { icon: { fntlIcon: FontelloIcon.Earpiece }, bgColor: null, label: 'shared.icons.earpiece', key: 'earpiece' },
    forms: { icon: { faIcon: 'far fa-file-alt' }, bgColor: css.brandingColors.W_LT_BLUE, label: 'forms.forms', key: 'forms' },
    geofences: { icon: { faIcon: 'fas fa-draw-polygon' }, bgColor: css.brandingColors.W_RED, label: 'geofence.geofences', key: 'geofences' },
    holeWatch: { icon: { faIcon: 'fas fa-clipboard' }, bgColor: css.brandingColors.W_DK_BLUE, label: 'items.holeWatch', key: 'holeWatch' },
    items: { icon: { faIcon: 'fas fa-shapes' }, bgColor: css.colors.GRAY, label: 'items.items', key: 'items' },
    itemTypes: { icon: { faIcon: 'fas fa-list-alt' }, bgColor: css.colors.GRAY, label: 'configuration.page-title.item-types', key: 'itemTypes' },
    people: { icon: { faIcon: 'fas fa-user' }, bgColor: css.brandingColors.W_DK_BLUE, label: 'people', key: 'people' },
    peopleGroup: { icon: { faIcon: 'fas fa-user-group' }, bgColor: css.brandingColors.W_DK_BLUE, label: 'people', key: 'peopleGroup' },
    levels: { icon: { faIcon: 'fas fa-layer-group' }, bgColor: css.brandingColors.W_DK_BLUE, label: 'floor-plan.level', key: 'level' },
    lightLayers: { icon: { faIcon: 'fal fa-layer-group' }, bgColor: css.brandingColors.W_DK_BLUE, label: 'floor-plan.level', key: 'level' },
    lightCircleCheck: { icon: { faIcon: 'fal fa-circle-check' }, bgColor: css.brandingColors.W_DK_BLUE, label: 'light-circle-check', key: 'lightCircleCheck' },
    diagramPredecessor: { icon: { faIcon: 'fal fa-diagram-predecessor' }, bgColor: css.brandingColors.W_DK_BLUE, label: 'diagram-predecessor', key: 'diagramPredecessor' },
    reports: { icon: { faIcon: 'fas fa-file-contract' }, bgColor: css.brandingColors.W_GREEN, label: 'reports.reports', key: 'reports' },
    wilma: { icon: { faIcon: 'fas fa-broadcast-tower' }, bgColor: css.brandingColors.W_ORANGE, label: 'wilma.wilma', key: 'wilma' },
    contactTracing: { icon: { faIcon: 'fas fa-people-arrows' }, bgColor: css.brandingColors.W_LT_GRAY, label: 'configuration.contact-tracing', key: 'contactTracing' },
    facility: { icon: { faIcon: 'fas fa-building' }, bgColor: null, label: 'facility.facility', key: 'facility' },
    fingerprint: { icon: { faIcon: 'fas fa-fingerprint' }, bgColor: null, label: 'fingerprint.fingerprint', key: 'fingerprint' },
    infoCircle: { icon: { faIcon: 'fal fa-info-circle' }, bgColor: css.colors.GRAY, label: 'info-circle', key: 'info-circle' },
    details: { icon: { faIcon: 'fas fa-info' }, bgColor: css.colors.GRAY, label: 'details', key: 'details' },
    radio: { icon: { fntlIcon: FontelloIcon.Walt }, bgColor: null, label: 'shared.icons.radio', key: 'radio' },
    'site-vision': { icon: { fntlIcon: FontelloIcon.SiteVision }, bgColor: null, label: 'shared.icons.site-vision', key: 'site-vision' },
    meetings: { icon: { faIcon: 'fas fa-calendar-alt' }, bgColor: css.brandingColors.W_LT_GRAY, label: 'shared.icons.meetings', key: 'meetings' },
    configuration: { icon: { faIcon: 'fas fa-cog' }, bgColor: null, label: 'shared.icons.configuration', key: 'configruation' },
    mayday: { icon: { faIcon: 'fas fa-megaphone' }, bgColor: null, label: 'shared.icons.mayday', key: 'mayday' },
    music: { icon: { faIcon: 'fas fa-music' }, bgColor: null, label: 'shared.icons.music', key: 'music' },
    protect: { icon: { faIcon: 'fas fa-shield-alt' }, bgColor: null, label: 'shared.icons.protect', key: 'protect' },
    'hole-watch': { icon: { faIcon: 'fas fa-clipboard' }, bgColor: css.brandingColors.W_DK_BLUE, label: 'items.holeWatch', key: 'holeWatch' },
    safety: { icon: { faIcon: 'fas fa-shield-alt' }, bgColor: null, label: 'shared.icons.safety', key: 'safety' },
    'comms-utilization': { icon: { fntlIcon: FontelloIcon.Walt }, bgColor: null, label: 'shared.icons.comms-utilization', key: 'commsUtilization' },
    'devices': { icon: { faIcon: 'fas fa-eye' }, bgColor: null, label: 'shared.icons.devices', key: 'devices' },
    'insights': { icon: { faIcon: 'fas fa-lightbulb-on' }, bgColor: null, label: 'shared.icons.insights', key: 'insights' },
    'gas-monitor': { icon: { faIcon: 'fa-regular fa-gauge-simple-high' }, bgColor: css.brandingColors.W_DK_BLUE, label: 'items.gasMonitor', key: 'gasMonitor' },
    location: { icon: { faIcon: 'fas fa-map-marker-alt' }, bgColor: null, label: null, key: 'location' },
    floorPlans: { icon: { faIcon: ' fas fa-layer-group' }, bgColor: null, label: 'configuration.facility-add.levels.levels', key: 'levels' },
    worker: { icon: { faIcon: 'fas fa-user-hard-hat' }, bgColor: null, key: 'worker' },
    workflow: { icon: { faIcon: 'fas fa-sitemap' }, bgColor: null, label: 'configuration.page-title.workflows', key: 'workflows' },
    channels: { icon: { faIcon: 'fas fa-comment' }, bgColor: null, label: 'configuration.page-title.channels', key: 'channels' },
    wifiRouters: { icon: { faIcon: 'fas fa-wifi' }, bgColor: css.colors.LT_BLUE, label: 'generics.wifi-routers', key: 'wifiRouters' },
    waltStatus: { icon: { faIcon: 'fas fa-battery-bolt' }, bgColor: null, label: 'walt.status', key: 'waltStatus' },
    waltSignal: { icon: { fntlIcon: FontelloIcon.WaltSignal}, bgColor: null, label: 'walt.signal', key: 'waltSignal' },
    signal: { icon: { faIcon: 'fas fa-signal' }, bgColor: null, label: 'signal', key: 'signal' },
    permissionGroups: { icon: { faIcon: 'fas fa-key' }, bgColor: null, label: 'permission-groups.permission-groups', key: 'permissionGroups' },
    clock: { icon: { faIcon: 'fas fa-clock' }, bgColor: null, label: 'null', key: 'clock' },
    wrangler: { icon: { fntlIcon: FontelloIcon.Wrangler }, bgColor: null, label: 'square-bolt', key: 'square-bolt' },
    'wrangler-group': { icon: { fntlIcon: FontelloIcon.WranglerGroup }, bgColor: null, label: 'square-bolt-group', key: 'square-bolt-group' },
    unpinned: { icon: { fntlIcon: FontelloIcon.Unpinned }, bgColor: null, label: 'unpinned', key: 'unpinned' },
    sites: { icon: { faIcon: 'fas fa-building' }, label: 'facility.facility', key: 'sites' },
    wispAssigned: { icon: { faIcon: 'fa-solid fa-circle-check' }, label: 'shared.person.wisp-assigned', key: 'wispAssigned' },
    wispNotAssigned: { icon: { faIcon: 'fa-solid fa-circle-xmark' }, label: 'shared.person.wisp-not-assigned', key: 'wispNotAssigned' },
    wisp: { icon: { fntlIcon: FontelloIcon.Wisp }, bgColor: css.brandingColors.W_DK_GRAY, label: 'wisp.wisp-id', key: 'wisp' },
    badgeAssigned: { icon: { faIcon: 'fa-solid fa-circle-check' }, label: 'shared.person.badge-assigned', key: 'badgeAssigned' },
    badgeNotAssigned: { icon: { faIcon: 'fa-solid fa-circle-xmark' }, label: 'shared.person.badge-not-assigned', key: 'badgeNotAssigned' },
    badge: { icon: { faIcon: 'fa-solid fa-id-badge' }, bgColor: css.brandingColors.W_DK_GRAY, label: 'shared.person.badge', key: 'badge' },
    dead: { icon: {faIcon:'fa-solid fa-skull-crossbones'}, label:'null', key:'dead'},
    readOnly: { icon: {faIcon:'fa-solid fa-pencil-slash'}, label:'configuration.admin-defined-channels.read-only', key:'readOnly'},
    confinedSpace: { icon: {}, label:'configuration.item-types.confined-space', key:'confinedSpace'},
    roip: { icon: {faIcon:'fa-solid fa-walkie-talkie'}, label:'configuration.admin-defined-channels.roip', key:'roip'},
    drc: { icon: {faIcon:'fa-solid fa-walkie-talkie'}, label:'configuration.admin-defined-channels.dedicated-radio-channel', key:'dedicatedRadioChannel'},
    hpc: { icon: {faIcon:'fa-solid fa-comment-exclamation'}, label:'configuration.channels.notifications.high-priority-channel', key:'hpc'},
    key: { icon: {faIcon:'fa-solid fa-key'}, label:'configuration.channels.notifications.key-channel', key:'keyChannel'},
    circlePlus: { icon: {faIcon:'fa-solid fa-circle-plus'}, label:'null', key:'circlePlus'},
};

export const DEFAULT_ITEM_TYPE_ICON = 'fas fa-question-circle';
export const DEFAULT_ALERT_TYPE_ICON = 'fas fa-question-circle';
